import LoginPage from "./elements/login/login.js"; // Change to capitalize the component
import {Navigate, BrowserRouter, Routes, Route} from 'react-router-dom'
import DashboardPage from "./pages/dashboardpage.js";
import UserPage from "./pages/userpage.js"

function App() {
  return (
    <BrowserRouter basename="/JPP">
      <Routes>
        <Route index element={<LoginPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/user" element={<UserPage />} />

        {/* Protect dashboard route */}
        <Route 
          path="/dashboard" 
          element={
            <PrivateRoute>
              <DashboardPage />
            </PrivateRoute>
          } 
        />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

function PrivateRoute({ children }) {
  const isLoggedIn = localStorage.getItem('isLoggedIn'); // Check login status
  return isLoggedIn ? children : <Navigate to="/login" />;
}

function NotFound() {
  return (
    <div style={{ textAlign: 'center', marginTop: '20%' }}>
      <h1>404</h1>
      <p>Page Not Found</p>
    </div>
  );
}

export default App;
