import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "./index.css"
import "./elements/login/login.css"
import "./elements/dashboard/userList.css"
import "./elements/header/header.css"

const root = ReactDOM.createRoot(document.querySelector('.root'));
root.render(
    <App />
);

