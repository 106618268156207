import { useNavigate } from 'react-router-dom';
import axios from "axios";

export default function MainHeader() { // Capitalized component name
    const navigate = useNavigate(); // Use the navigate hook

    const handleLogout = async () => {
        try {
          // Request backend to clear session
          const response = await axios.get("logout.php", {
            withCredentials: true, // Ensure cookies are sent
          });
    
          if (response.data.success) {
            localStorage.removeItem('isLoggedIn'); // Clear login status on frontend
            navigate('/login'); // Redirect to login page
          } else {
            console.error('Logout failed:', response.data.message);
          }
        } catch (error) {
          console.error('Logout error:', error);
        }
      };

    return (
        <div className="header">
            <button className="logout_button" onClick={handleLogout}>
                Logout
            </button>
        </div>
    );
}
