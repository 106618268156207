import React from 'react';
import MainHeader from "../elements/header/header.js"
import Dashboard from "../elements/dashboard/dashboard.js";

export default function DashboardPage() {
    return (
        <>
        <MainHeader />
        <Dashboard /> 
        </>
    );
}