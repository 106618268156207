import { useNavigate } from "react-router-dom";
import axios from "axios";

export default function LoginPage() {
  // Change to capitalize the component name
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const username = formData.get("username");
    const password = formData.get("password");

    try {
      const response = await axios.post("login.php", {
        username,
        password,
      },{ withCredentials: true });

      const data = response.data; // Directly use the response data from axios

      if (data.success) {
        localStorage.setItem("isLoggedIn", "true");
        navigate("/dashboard");
      } else {
        alert(data.message);
      }
    } catch (err) {
      console.error(err); // Log the error for debugging
      alert("Login failed!");
    }
  };

  return (
    <div className="login_page">
            <form className="login_container" onSubmit={handleLogin}>
                <h1 className="login_title">Login</h1>
                <input className="login_username" type="text" name="username" placeholder="username" required/>
                <input className="login_password" type="password" name="password" placeholder="password" required/>
                <button className="login_button" type="submit">Login</button>
            </form>
        </div>
  );
}
