import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import './userPage.css';

const UserPage = () => {
    const [searchParams] = useSearchParams();
    const [userInfo, setUserInfo] = useState(null);
    const [userOptionCodes, setUserOptionCodes] = useState([]);
    const [serialNumber, setSerialNumber] = useState("");
    const [error, setError] = useState(null);

    const queryParams = Object.fromEntries([...searchParams.entries()]);

    useEffect(() => {
        // Function to fetch user info from both APIs
        const fetchUserInfo = async () => {

                // Send GET request to PHP backend with query parameters
            if (queryParams && queryParams.software_version && queryParams.system_type)
            {
                const getUserResponse = await axios.get('postUserInfo.php', { params: queryParams });
                console.log(getUserResponse.data); // Log the response data
            }

            await axios.get('registerUser.php', 
                { params: { serial_number: queryParams.serial_number } 
            }).then((postUserInfoResponse) => {
                if (postUserInfoResponse.data.status === 'success') 
                    {
                        setUserOptionCodes(
                            postUserInfoResponse.data.data
                        );
                        setSerialNumber(queryParams.serial_number)
                }
                console.log(postUserInfoResponse.data.data); // Log the data from the response
            })

            await axios.post('postUserInfo.php', {
                    serial_number: queryParams.serial_number
                }).then((postUserInfoResponse) => {
                    if (postUserInfoResponse.data.status === 'success') 
                        {
                        setUserInfo(postUserInfoResponse.data.data); // Set initial user info
                    }
                    console.log(postUserInfoResponse.data.data); // Log the data from the response
                })

                
                
        };

        fetchUserInfo(); // Call the function to fetch user info
    }, []); // Add queryParams as a dependency

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!userInfo) {
        return <div>Loading...</div>;
    }

    return (
        <div className="user_info_parent">
      <h1>User Information</h1>
      <div className="user_info_header">
        <div className="info_row">
          <p className="info_key">Serial number:</p>
          <p className="info_value">{serialNumber}</p>
        </div>
        <div className="info_row">
          <p className="info_key">EML 1100 code:</p>
          <p className="info_value">{userOptionCodes.eml1100 || 'Not available...'}</p>
        </div>
        <div className="info_row">
          <p className="info_key">EML 1200 code:</p>
          <p className="info_value">{userOptionCodes.eml1200 || 'Not available...'}</p>
        </div>
      </div>
      <h2 className="user_history_title">Register history:</h2>
      <div className="user_info_history">
        <UserInfoList userInfo={userInfo}/>
      </div>
    </div>
    );
};
const UserInfoBox = ({ user }) => {
    return (
        <div className="user_info_box">
            {Object.entries(user).map(([key, value]) => (
                <div className="info_row" key={key}>
                    <p className="info_key">{formatKey(key)}:</p>
                    <p className="info_value">{value || "N/A"}</p>
                </div>
            ))}
        </div>
    );
};

// Function to format keys (e.g., "imo_number" → "IMO Number")
const formatKey = (key) => {
    return key
        .replace(/_/g, " ")  // Replace underscores with spaces
        .replace(/\b\w/g, (char) => char.toUpperCase());  // Capitalize each word
};

const UserInfoList = ({ userInfo }) => {
    return (
        <>
            {userInfo?.map((user, index) => (
                <UserInfoBox key={index} user={user} />
            ))}
        </>
    );
};


export default UserPage;
