export default function UserList({ userList = [], onHandleViewSelect = () => {} }) {
    const sortedUserList = [...userList].sort((a, b) =>
        (a.serial_number || "").localeCompare(b.serial_number || "")
    );

    return (
        <div className="user_list">
            {sortedUserList.map((userInfo) => (
                <UserListSingleElement
                    key={userInfo.serial_number || Math.random()} // Fallback if no serial_number
                    userInfo={userInfo}
                    onHandleViewSelect={() => onHandleViewSelect(userInfo.serial_number)}
                />
            ))}
        </div>
    );
}

function UserListSingleElement({ userInfo = {}, onHandleViewSelect }) {
    return (
        <div onClick={onHandleViewSelect} className="user_list_single_element">
            <div className="user_list_content">
                {Object.entries(userInfo)
                    .filter(([key]) => key !== "id") // Exclude "id"
                    .map(([key, value]) => (
                        <div key={key} className="user_field">
                            <span className="user_label">{key.replace(/_/g, ' ')}:</span>
                            <div className="user_value">{value}</div>
                        </div>
                    ))}
            </div>
        </div>
    );
}