import { useEffect, useState } from "react";
import UserList from "./userList";
import axios from "axios";

export default function Dashboard() {
  const [isSingleUserPage, setIsSingleUserPage] = useState(false);
  const [userList, setUserList] = useState([]);
  const [singleUser, setSingleUser] = useState([]);
  const [singleUserCodes, setSingleUserCodes] = useState([]);

  useEffect(() => {
    fetchUsersLatestInfo();
  }, []);

  const fetchUsersLatestInfo = async () => {
    await axios.get("getAllUsersInfo.php").then((response) => {
      const usersObject = response.data.data; // Provide a fallback
      const usersArray = Object.values(usersObject); // Convert the object to an array
      setUserList(usersArray);
    });
  };

  function handleViewSelect(serial_number = "") {
    setIsSingleUserPage((prevState) => !prevState); // Safely toggles the state
    if (serial_number !== "") {
      // Check if the array is not empty

      setSingleUser([]);

    
      getSingleUserHistory(serial_number);
      getSingleUserCodes(serial_number);
    }
    else
    {
      setSingleUser([]);

    }
  }

  async function getSingleUserHistory(serial_number) {
    await axios.post("postUserInfo.php", { serial_number }).then((response) => {
      setSingleUser(response.data.data);
      console.log(response.data.data);
    });
  }

  async function getSingleUserCodes(serial_number) {
    const response = await axios.get(
      `registerUser.php?serial_number=${serial_number}`
    );
    if (response.data.status === "success") {
      setSingleUserCodes(response.data.data); // Assuming the data is an array and you want the first item
      console.log(response.data.data); // Log the user information
    } else {
      console.error(
        "Error fetching user info:",
        response.data.message || "No data returned"
      );
      setSingleUserCodes({}); // Set to empty object or handle accordingly
    }
  }

  return (
    <div className="dashboard">
      {/* User List Filter and List */}
      <div
        className={
          isSingleUserPage ? "dashboard_parent slide-left" : "dashboard_parent"
        }
      >
        <h1>Registered users (last update):</h1>
        <UserList userList={userList} onHandleViewSelect={handleViewSelect} />
        <h1>Add user manually:</h1>
        <AddUserManualy refreshUserList={fetchUsersLatestInfo} />
      </div>

      {/* Single User Info */}
      <div
        className={
          !isSingleUserPage
            ? "dashboard_parent slide-right"
            : "dashboard_parent"
        }
      >
        <button className="back_button" onClick={handleViewSelect}>
          Back to user list
        </button>
        <h1>Change user info:</h1>
        {singleUserCodes.serial_number ? ( // Only render if serial_number exists
          <UserInfo
            serial_number={singleUserCodes.serial_number}
            eml1100={singleUserCodes.eml1100}
            eml1200={singleUserCodes.eml1200}
            refreshUserList={fetchUsersLatestInfo}
          />
        ) : (
          <p>No user selected.</p> // Fallback message
        )}
        <h1>User info:</h1>
        <UserList userList={singleUser} />
      </div>
    </div>
  );
}

function UserInfo({
  serial_number = "",
  eml1100 = "",
  eml1200 = "",
  refreshUserList,
}) {
  // State variables for editable fields
  const [eml1100Code, setEml1100Code] = useState(eml1100);
  const [eml1200Code, setEml1200Code] = useState(eml1200);
  const [buttonColor, setButtonColor] = useState("");

  const updateUserCode = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    axios
      .post("registerUser.php", {
        serial_number, // Use the unchangeable serial number
        eml1100: eml1100Code,
        eml1200: eml1200Code,
      })
      .then((response) => {
        console.log(response);
        if (response.data.status === "success") {
          setButtonColor("green"); // Set button color to green
          setTimeout(() => setButtonColor(""), 5000); // Revert to default color after 5 seconds
        } else {
          setButtonColor("red"); // Set button color to green
          setTimeout(() => setButtonColor(""), 5000); // Revert to default color after 5 seconds
        }
      });

    refreshUserList(); // Refresh the user list after submitting changes
  };

  return (
    <form className="add_user_manualy" onSubmit={updateUserCode}>
      <p>Serial number:</p>
      {/* Serial number is not editable */}
      <input type="text" name="serial_number" value={serial_number} disabled />

      <p>EML 1100 code:</p>
      <input
        className="manual_user_1100"
        type="text"
        name="eml1100"
        value={eml1100Code}
        onChange={(e) => setEml1100Code(e.target.value)} // Update state on change
        placeholder="User doesn't have this code"
      />

      <p>EML 1200 code:</p>
      <input
        className="manual_user_1200"
        type="text"
        name="eml1200"
        value={eml1200Code}
        onChange={(e) => setEml1200Code(e.target.value)} // Update state on change
        placeholder="User doesn't have this code"
      />

      <button type="submit" style={{ backgroundColor: buttonColor }}>
        Submit changes
      </button>
    </form>
  );
}

function AddUserManualy({ refreshUserList }) {
    const [buttonColor, setButtonColor] = useState("");
  const addNewUser = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    const formData = new FormData(e.target);
    const serial_number = formData.get("serial_number") || ""; // Default to "" if not provided
    const eml1100 = formData.get("eml1100") || ""; // Default to "" if not provided
    const eml1200 = formData.get("eml1200") || ""; // Default to "" if not provided

    axios
      .post("registerUser.php", {
        serial_number,
        eml1100,
        eml1200,
      })
      .then((response) => {
        console.log(response);
        if (response.data.status === "success") {
            setButtonColor("green"); // Set button color to green
            setTimeout(() => setButtonColor(""), 5000); // Revert to default color after 5 seconds
          } else {
            setButtonColor("red"); // Set button color to green
            setTimeout(() => setButtonColor(""), 5000); // Revert to default color after 5 seconds
          }
      });

    refreshUserList(); // Refresh the user list after adding a new user
  };

  return (
    <form className="add_user_manualy" onSubmit={addNewUser}>
      <input
        className="manual_user_serial"
        type="text"
        name="serial_number"
        placeholder="Serial number"
        defaultValue="" // Initialize as empty
      />
      <input
        className="manual_user_1100"
        type="text"
        name="eml1100"
        placeholder="Option for EML 1100"
        defaultValue="" // Initialize as empty
      />
      <input
        className="manual_user_1200"
        type="text"
        name="eml1200"
        placeholder="Option for EML 1200"
        defaultValue="" // Initialize as empty
      />
      <button type="submit" style={{ backgroundColor: buttonColor }}>
        Add new user
      </button>
    </form>
  );
}
